// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/home/Index.vue")
        },
        {
          path: "ozet",
          name: "Özet",
          component: () => import("@/views/ozet/Index.vue"),
          meta: { src: require("@/assets/about.jpg") }
        },
        {
          path: "amac",
          name: "Amaç",
          component: () => import("@/views/amac/Index.vue"),
          meta: { src: require("@/assets/about.jpg") }
        },
        {
          path: "konu",
          name: "Konu ve Kapsam",
          component: () => import("@/views/konu/Index.vue"),
          meta: { src: require("@/assets/contact.jpg") }
        },
        {
          path: "proje",
          name: "Proje Ekibi",
          component: () => import("@/views/proje/Index.vue"),
          meta: { src: require("@/assets/pro.jpg") }
        },
        {
          path: "*",
          name: "FourOhFour",
          component: () => import("@/views/404/Index.vue")
        }
      ]
    }
  ]
});

export default router;
